<template>
  <div class="fixed_box" :class="{ scrollbar:hasScrollbar}">
    <!-- {{ hasScrollbar }} -->
    <!-- :class="!canSee?'dohand-b-position':''"  -->
    <div class="dohand dohand-b"  id="yushe" ref="yueshe">
      <span>
        <i @click="popup_b = true" style="font-size:18px;margin-right:10px" class="el-icon-s-tools"></i>
        <span class="custom_radio">
          <el-radio v-model="newAmount" v-for="noj in constNum" :key="noj.num" :label="noj.num">{{noj.num}}</el-radio>
        </span>
      </span>

      <div class="amount">
        金额 <input type="number" :value="amount" @input="changeAmount" />
      </div>
      <div class="preset" :class="quick_c ? 'selected' : ''" @click="changeQuickC">
        预设
      </div>
      <div class="btn none" v-if="xzList.length==0">确认</div>
      <div class="btn yes" v-if="xzList.length>0" @click="clickShowXZTK">确认</div>
      <div class="btn yes" @click="resetOn">重置</div>

    </div>

    <div class="popup" v-if="popup_b">
      <div class="popup-main">
        <div class="popup-title">
          <span>快选金额</span>
          <div>
            <i class="van-icon van-icon-cross" @click="popup_b = false">x</i>
          </div>
        </div>
        <div class="popup-content">
          <div class="quicks-box">
            <ul>
              <li v-for="(item, index) in yuseData" :key="index">
                <input type="text" v-model="item.num" placeholder="快选金额" />
              </li>
            </ul>
            <div class="state">
              <div role="radiogroup" class="van-radio-group">
                <div class="van-radio" @click="changeQucikB(1)">
                  <span class="yuan" :class="quick_b ? 'selected' : ''"></span>
                  <span class="van-radio__label">启用</span>
                </div>
                <div class="van-radio" @click="changeQucikB(0)">
                  <span class="yuan" :class="!quick_b ? 'selected' : ''"></span>
                  <span class="van-radio__label">停用</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-btn">
          <span @click="saveData">保存</span>
          <span @click="popup_b = false">取消</span>
        </div>

      </div>
    </div>
    <!-- <div class="fixedDiv">
      <p>{{h1}}</p>
      <p>{{h2}}</p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: ["isDisabled"],
  data() {
    return {
      popup_b: false,
      yuseData: [
        { num: "10" },
        { num: "20" },
        { num: "50" },
        { num: "100" },
        { num: "500" },
        { num: "1000" },
      ],
      constNum: [
        { num: "10" },
        { num: "20" },
        { num: "50" },
        { num: "100" },
        { num: "500" },
        { num: "1000" },
      ],

      canSee: true,
      h1: "",
      newAmount: 0,
    };
  },
  computed: {
    ...mapState([
      "amount",
      "quick_c",
      "quick_b",
      "xzList",
      "ISOPEN",
      "xzVisible",
      'hasScrollbar'
    ]),
  },
  created() {
    let cnum = localStorage.getItem("constNum");
    if (cnum) {
      let arr = JSON.parse(cnum);
      let b = arr.some((item) => item.num);
      if (b) {
        this.yuseData = JSON.parse(JSON.stringify(arr));

        this.constNum = arr.filter((item) => item.num);
      } else {
        this.constNum = [
          { num: "10" },
          { num: "20" },
          { num: "50" },
          { num: "100" },
          { num: "500" },
          { num: "1000" },
        ];
        this.yuseData = [
          { num: "10" },
          { num: "20" },
          { num: "50" },
          { num: "100" },
          { num: "500" },
          { num: "1000" },
        ];
      }
    }
  },
  mounted() {
    // this.$nextTick(() => {
    window.yushetime = window.setTimeout(() => {
      let scrollDom = document.getElementById("scroll");
      let yushe = document.getElementById("yushe");
      this.h2 = yushe.offsetTop;

      if (scrollDom.scrollTop + scrollDom.offsetHeight >= this.h2) {
        this.canSee = true;
      } else {
        this.canSee = false;
      }
      // console.log(this.canSee, "定位class");
      scrollDom.addEventListener("scroll", (e) => {
        if (scrollDom.scrollTop + scrollDom.offsetHeight >= this.h2) {
          this.canSee = true;
        } else {
          this.canSee = false;
        }
      });
      window.clearTimeout(window.yushetime);
    }, 300);
    // });
    let that = this;
    document.addEventListener("keyup", (e) => {
      if (e.keyCode == 13 || e.code == "Enter") {
        if (that.xzList.length > 0 && this.ISOPEN && !this.xzVisible) {
          that.clickShowXZTK();
        }
      }
    });
  },
  beforeDestroy() {
    // console.log(window.timeout, "预设定时器是否存在");
    window.clearTimeout(window.timeout);
  },
  watch: {
    amount(val) {
      this.newAmount = val;
    },
    newAmount(val) {
      this.$store.commit("setAmount", val);
    },
  },
  methods: {
    showResultGo() {
      this.$store.commit("resultGoBool", true);
    },
    clickShowXZTK() {
      // console.log(this.xzList, "下注11111111111111111");
      let b = this.xzList.every((item) => !item.money);

      if (!b) {
        this.$store.commit("showOrHideXZTK", true);
      } else {
        this.$message.warning("请输入金额！");
      }
    },
    // 重置
    resetOn() {
      // 清掉 预设
      this.$store.commit("setQuickC", 0);
      // 清掉 金额
      this.$store.commit("setAmount", "");
      this.$store.commit("resetCountAdd");
    },
    // 修改amount
    changeAmount(val) {
      let val1 = val.target.value;

      this.$store.commit("setAmount", val1);
    },
    //   保存数据
    saveData() {
      let arr = [];
      if (this.quick_b) {
        let bool_b = this.yuseData.some((item) => item.num);
        if (bool_b) {
          this.constNum = this.yuseData.filter((item) => item.num);
          arr = this.yuseData;
        } else {
          this.constNum = [
            { num: "10" },
            { num: "20" },
            { num: "50" },
            { num: "100" },
            { num: "500" },
            { num: "1000" },
          ];
          arr = this.constNum;
        }
        localStorage.setItem("constNum", JSON.stringify(arr));
      } else {
        this.constNum = [
          { num: "10" },
          { num: "20" },
          { num: "50" },
          { num: "100" },
          { num: "500" },
          { num: "1000" },
        ];
        localStorage.setItem("constNum", JSON.stringify(this.constNum));
      }

      this.popup_b = false;
    },

    changeQuickC() {
      let a;
      if (this.quick_c) {
        a = 0;
      } else {
        a = 1;
      }
      this.$store.commit("setQuickC", a);
    },
    //   启用
    changeQucikB(b) {
      this.$store.commit("setQuickB", b);
    },
  },
};
</script>
<style>

.custom_radio .el-radio__input {
  display: none;
}
.custom_radio .el-radio {
  margin-right: 3px;
}
.custom_radio .el-radio__label {
  color: #c2455c;
  padding-left: 0;
  padding: 0 5px;
  font-weight: bold;
  border-radius: 4px;
  background: #f3e2cf;
  border: 1px solid #e9d2b7;
}
.custom_radio .el-radio__input.is-checked + .el-radio__label {
  color: #c2455c;
}
/* .skin-blue .custom_radio .el-radio__input + .el-radio__label,
.skin-blue .custom_radio .el-radio__input.is-checked + .el-radio__label {
  color: #fff;
  background: #438ff2;
}
.skin-brown .custom_radio .el-radio__input + .el-radio__label,
.skin-brown .custom_radio .el-radio__input.is-checked + .el-radio__label {
  
  color: #fff;
  background: #e1c3a0;
}
.skin-red .custom_radio .el-radio__input + .el-radio__label,
.skin-red .custom_radio .el-radio__input.is-checked + .el-radio__label {
  
  color: #fff;
  background: #ea7389;
} */
.fixedDiv {
  width: 500px;
  height: 100px;
  position: fixed;
  top: 50px;
  right: 50px;
  background: #fff;
  z-index: 100000;
}
/* popup 样式 */
.container .main .dohand.dohand-b-position {
  margin-top: 30px;
  position: fixed;
  bottom: 28px;
  left: 200px;
  width: 830px;
  padding-left: 5px;
  -webkit-box-shadow: 0 -2px 5px #ccc;
  box-shadow: 0 -2px 5px #ccc;
}
.skin-blue .popup .popup-main .popup-btn span:first-child {
  background: #1e9fff;
}
.skin-red .popup .popup-main .popup-btn span:first-child {
  background: #c2455c;
}
.skin-brown .popup .popup-main .popup-btn span:first-child {
  background: #dfb55e;
}
.popup .popup-main .popup-btn span:nth-child(2) {
  margin-left: 10px;
  background: #fff;
  border: 1px solid #ccc;
}
.popup .popup-main .popup-btn span:first-child {
  color: #fff;
}
.popup .popup-main .popup-btn span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 30px;
  padding: 0 15px;
  border-radius: 2px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.popup .popup-main .popup-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background: #f3f2f2;
  padding: 10px;
}
.skin-brown .van-radio .yuan.selected {
  background: #dfb55e;
}
.skin-red .van-radio .yuan.selected {
  background: #6a1f2d;
}
.skin-blue .van-radio .yuan.selected {
  background: #2765b5;
}
.van-radio .yuan {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid lightgray;
  border-radius: 8px;
}
.van-radio__label {
  margin-left: 8px;
  color: #323233;
  line-height: 20px;
}
.van-radio__icon {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  height: 1em;
  font-size: 20px;
  line-height: 1em;
  cursor: pointer;
}
.van-radio {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.app-loading,
.quicks-box .state .van-radio-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.quicks-box .state .van-radio-group {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.quicks-box .state {
  padding: 5px 30px;
}
.quicks-box ul li input {
  border: 1px solid #ccc;
  height: 25px;
  line-height: 25px;
  width: 100%;
  text-indent: 5px;
}
button,
input,
textarea {
  color: inherit;
  font: inherit;
}
.quicks-box ul li {
  margin-bottom: 5px;
}
.quicks-box {
  width: 240px;
  padding: 5px 30px;
}
.popup .popup-main{
  position: absolute;
  top: 103px;
}
.popup .popup-main .popup-content {
  background: #fff;
  width: 550px;
  height: 330px;
 
}
.popup .popup-main .popup-title i {
  color: #fff;
}

.van-icon,
.van-icon:before {
  display: inline-block;
}
.van-icon {
  position: relative;
  font: normal normal normal 14px/1 vant-icon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.skin-brown .popup .popup-main .popup-title {
  background: #dfb55e;
  color: #fff;
}
.skin-red .popup .popup-main .popup-title {
  background: #c2455c;
  color: #fff;
}
.skin-blue .popup .popup-main .popup-title {
  background: #2161b3;
  color: #fff;
}
.popup .popup-main .popup-title div {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 42px;
  color: #666;
  cursor: pointer;
  font-size: 28px;
}
.popup .popup-main .popup-title,
.popup .popup-main .popup-title div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
}
.popup .popup-main .popup-title {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 20px;
  border-bottom: 1px solid #eee;
  background: #f8f8f8;
}
.popup .hide {
  -webkit-animation: popup-out 0.2s linear infinite forwards;
  animation: popup-out 0.2s linear infinite forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.popup .show {
  -webkit-animation: popup-show 0.2s linear infinite forwards;
  animation: popup-show 0.2s linear infinite forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.popup .popup-main {
   
  -webkit-box-shadow: 1px 1px 50px rgb(0 0 0 / 30%);
  box-shadow: 1px 1px 50px rgb(0 0 0 / 30%);
  overflow: hidden;
  border-radius: 2px;
}
.popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
}
/* 底部设置 */
.container .main .dohand .btn2.b {
  left: 170px;
}
.container .main .dohand .btn2.a {
  right: 0;
}
.container .main .dohand .btn.none {
  background: #999;
  border: 1px solid #999;
}
.container .main .dohand .btn2 {
  position: absolute;
  top: 10px;
  width: 62px;
  height: 18px;
  line-height: 18px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ff925f),
    to(#ff7340)
  );
  background: linear-gradient(180deg, #ff925f, #ff7340);
  color: #fff;
  border: 1px solid #ff6835;
  cursor: pointer;
  text-align: center;
}
.skin-brown .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0,#dfb55e ),
    to(#b99770)
  );
  background: linear-gradient(180deg,#e1bf7a, #69401f);
  border: 1px solid #dfb55e;
}
.skin-blue .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #5b8ac7),
    to(#2765b5)
  );
  background: linear-gradient(180deg, #5b8ac7, #2765b5);
  border: 1px solid #1e57a0;
}
.skin-red .bodyer .container .main .dohand .btn.yes {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #d87c86),
    to(#6a1f2d)
  );
  background: linear-gradient(180deg, #d87c86, #6a1f2d);
  border: 1px solid #ab374a;
}
.container .main .dohand .btn {
  margin-left: 15px;
  width: 58px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.container .main .dohand .amount input {
  margin-left: 5px;
  border: 1px solid #b0b0b0;
  height: 18px;
  padding: 0 2px;
  width: 65px;
  background: url("../assets/input.gif") no-repeat 0 0;
  color: #333;
  font-weight: 400;
}
.container .main .dohand .amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  font-weight: 700;
}
.container .main .dohand .preset.selected:after {
  position: absolute;
  content: "";
  left: 3px;
  top: 4px;
  width: 10px;
  height: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 2px solid #666;
  border-bottom: 2px solid #666;
  -webkit-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.container .main .dohand .preset:before {
  position: absolute;
  content: "";
  left: 0;
  top: 1px;
  border: 1px solid #bbb;
  height: 14px;
  width: 14px;
}
.container .main .dohand .preset {
  margin-left: 10px;
  position: relative;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  padding-left: 20px;
  cursor: pointer;
}
.skin-brown .bodyer .container .main .dohand {
  color: #dfb55e;
}
.skin-red .bodyer .container .main .dohand {
  color: #6a1f2d;
}
.skin-blue .bodyer .container .main .dohand {
  color: #38539a;
}
.container .main .dohand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 10px 0;
  /* background: #fff; */
  line-height: 20px;
}
</style>
